import { Component, OnInit,ViewChild } from '@angular/core';
import { NgForm, NgModel,NgModelGroup } from '@angular/forms';
import { DataService} from '../../../services/data.service';
import { HttpErrorResponse} from '@angular/common/http';
import { LoginService } from '../../../services/login.service';
import { Router} from "@angular/router";
import { PhotoInfo } from '../../../models/PhotoInfo';
import { BasicInfo } from '../../../models/BasicInfo';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { Member } from '../../../models/Member';
import { MemberService } from '../../../services/member.service';
import { AddMemberService } from '../../../services/add-member.service';
import { Gothram } from '../../../models/Gothram';
import { Subsect } from '../../../models/Subsect';
import { Language } from '../../../models/Language';
import { Education } from '../../../models/Education';
import { Profession } from '../../../models/Profession';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})
export class AddMemberComponent implements OnInit {
  @ViewChild("f") formData:NgForm;
  @ViewChild("mno") mobileNo:NgModel;
  @ViewChild("ph") ph:NgModelGroup;
  
  member: Member = new Member();
  
  err=false;
  errorMessage:string="could not save the record";
  gothrams: Gothram[];
  subsects: Subsect[];
  motherTongues: Language[];    
  languages: Language[];
  educations: Education[];
  professions: Profession[];
 
  heights: string[]=['below 4 feet','4 feet','4 feet 1 inche','4 feet 2 inches','4 feet 3 inches','4 feet 4 inches','4 feet 5 inches','4 feet 6 inches','4 feet 7 inches','4 feet 8 inches','4 feet 9 inches','4 feet 10 inches','4 feet 11 inches','5 feet','5 feet 1 inche','5 feet 2 inches','5 feet 3 inches','5 feet 4 inches','5 feet 5 inches','5 feet 6 inches','5 feet 7 inches','5 feet 8 inches','5 feet 9 inches','5 feet 10 inches','5 feet 11 inches','6 feet','above 6 feet'];
  weights: string[]=['below 40 kgs ','40 kgs','41 Kgs','42 Kgs','43 Kgs','44 Kgs','45 Kgs','46 Kgs','47 Kgs','48 Kgs','49 Kgs','50 Kgs','51 Kgs','52 Kgs','53 Kgs','54 Kgs','55 Kgs','56 Kgs','57 Kgs','58 Kgs','59 Kgs','60 Kgs','61 Kgs','62 Kgs','63 Kgs','64 Kgs','65 Kgs','66 Kgs','67 Kgs','68 Kgs','69 Kgs','70 Kgs','71 Kgs','72 Kgs','73 Kgs','74 Kgs','75 Kgs','76 Kgs','77 Kgs','78 Kgs','79 Kgs','80 Kgs','above 80 kgs'];
  rasis: string[]=['dont Know','Mesha','Vrishabha','Mithuna','Karkataka','Simha','Kanya','Tula','Vrishchika','Dhanus','Makara','Kumba','Meena','dont know'];
  stars: string[]=['dont know','Aswini','Bharani','krithiga','Rohini','Mrigasira','Arudra','Punarvasu','Pushyami','Aslesha','Magha','Purva Phalguni','Utthara Phalguni','Hastha','Chitra','Swathi','Visakha','Anuradha','Jyesta','Moola','Poorvashada','Uttharashada','Sravana','Dhanista','Sathabhisha','Purva Bhadra','Uttharabhadra','Revathi'];
  padams: string[]=['dont know','Ist Padam','2nd Padam','3rd Padam','4th Padam'];
  complexions: string[]=['Black','Good Looking','Wheatish','Fair','Very Fair'];
  horoscopeMatches: string[]=['Must','Not Required','Not Particular'];
 
  maritalStatuses: string[]=['Married','UnMarried','Divorcee-issues','Divorcee-noissues','Widower','Widow'];
  childrenList: string[]=['none','1','2','3','4','5','6','7','8','9','10'];
  physicalStatuses: string[]=['Normal','Mentally Retarded','Physically Handicapped'];
  contactRelations:string[]=['Self','Friend','Relative','Cousin','Father','Mother','Brother','Sister','Well-Wisher'];
  enteredByList:string[]=['Self','Friend','Relative','Cousin','Father','Mother','Brother','Sister','Well-Wisher'];
  familyStatuses:string[]=['Poor','Lower Middle Class','Middle Class','Upper Middle Class','Rich','Very Rich'];
	brothersNos:string[]=['None','1','2','3','4','5','6','7','8','9','10'];
	sistersNos:string[]=['None','1','2','3','4','5','6','7','8','9','10'];
  salaryList:string[]=['< 10,000','10,000-20,000','20,000-30,000','30,000-40,000','40,000-50,000','50,000-60,000','60,000-70,000','70,000-80,000','80,000-90,000',
  '90,000-1 lakh','1 lakh-2 lakhs','2 lakhs-3 lakhs','3 lakhs-4 lakhs','4 lakhs-5 lakhs','5 lakhs-6 lakhs','6 lakhs-7 lakhs','7 lakhs-8 lakhs','8 lakhs-9 lakhs','9 lakhs-10 lakhs',
  '10 lakhs-20 lakhs','20 lakhs-30 lakhs','30 lakhs-40 lakhs','40 lakhs-50 lakhs','50 lakhs-60 lakhs','60 lakhs-70 lakhs','70 lakhs-80 lakhs','80 lakhs-90 lakhs','90 lakhs-1 Crore','>1 Crore'];

  
  constructor(private addMemberService:AddMemberService,private router: Router,private dataService: DataService,private memberService: MemberService,private loginService :LoginService) {}
  
  onRegister(){
    console.log("this.formData.value="+JSON.stringify(this.formData.value));
    this.member=this.formData.value;
    this.member.photoInfo=this.addMemberService.photoInfo;
    console.log("this.member="+JSON.stringify(this.member));
  
    if(this.formData.valid){  
      this.memberService.addMember(this.member)
          .subscribe( data => {
         //   console.log("data=");
           // console.log(data);
            if(data!=null && data!=undefined ){ 
              this.loginService.loginInfo=data;
              this.addMemberService.result="success";
              this.err=false;
           //   this.formData.controls.value=null;
            //  this.router.navigate(['register-success']);
              alert("Member created successfully.");
            }else{
              this.addMemberService.result="failure";
              alert("Could not create the Member..");
            }
            
          },(error: HttpErrorResponse) => {
                  this.err=true;
                  console.log(error);
                  if(!error.error.errors==undefined){
                    this.errorMessage=error.error.errors[0].defaultMessage;
                      console.log (error.error.errors[0].defaultMessage);
                  }
            }
        
        );
      }
   }

  ngOnInit() {
  //  this.member.basicInfo=new BasicInfo();
    //this.member.basicInfo.mname="";
    //this.member.basicInfo.gender="Groom";
    this.dataService.getGothrams().subscribe(
          data => {
        //    console.log(data);
            this.gothrams=data;
          }
        );
        this.dataService.getSubsects().subscribe(
          data => {
         //   console.log(data);
            this.subsects=data;
          }
        );
        this.dataService.getEducations().subscribe(
          data => {
         //   console.log(data);
            this.educations=data;
          }
        );
        this.dataService.getProfessions().subscribe(
          data => {
         //   console.log(data);
            this.professions=data;
          }
        );
        this.dataService.getLanguages().subscribe(
          data => {
        //    console.log(data);
            this.languages=data;
            this.motherTongues=data;
          }
        );
  }

}


/*
import { Component, OnInit,ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Member } from '../../../models/Member';
import { MemberService } from '../../../services/member.service';
import { Gothram } from '../../../models/Gothram';
import { Subsect } from '../../../models/Subsect';
import { Language } from '../../../models/Language';
import { Education } from '../../../models/Education';
import { Profession } from '../../../models/Profession';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.css']
})
export class AddMemberComponent implements OnInit {
  member: Member = new Member();
  @ViewChild("f") formData :NgForm;
  constructor(public dialogRef: MatDialogRef<AddMemberComponent>,private router: Router, private memberService: MemberService) { }
  err=false;
  errorMessage:string="could not save the record";
  gothrams: Gothram[];
  subsects: Subsect[];
  motherTongues: Language[];    
  languages: Language[];
  educations: Education[];
  professions: Profession[];

  
  
  heights: string[]=['below 4 feet','4 feet','4 feet 1 inche','4 feet 2 inches','4 feet 3 inches','4 feet 4 inches','4 feet 5 inches','4 feet 6 inches','4 feet 7 inches','4 feet 8 inches','4 feet 9 inches','4 feet 10 inches','4 feet 11 inches','5 feet','5 feet 1 inche','5 feet 2 inches','5 feet 3 inches','5 feet 4 inches','5 feet 5 inches','5 feet 6 inches','5 feet 7 inches','5 feet 8 inches','5 feet 9 inches','5 feet 10 inches','5 feet 11 inches','6 feet','above 6 feet'];
  weights: string[]=['below 40 kgs ','40 kgs','41 Kgs','42 Kgs','43 Kgs','44 Kgs','45 Kgs','46 Kgs','47 Kgs','48 Kgs','49 Kgs','50 Kgs','51 Kgs','52 Kgs','53 Kgs','54 Kgs','55 Kgs','56 Kgs','57 Kgs','58 Kgs','59 Kgs','60 Kgs','61 Kgs','62 Kgs','63 Kgs','64 Kgs','65 Kgs','66 Kgs','67 Kgs','68 Kgs','69 Kgs','70 Kgs','71 Kgs','72 Kgs','73 Kgs','74 Kgs','75 Kgs','76 Kgs','77 Kgs','78 Kgs','79 Kgs','80 Kgs','above 80 kgs'];
  rasis: string[]=['dont Know','Mesha','Vrishabha','Mithuna','Karkataka','Simha','Kanya','Tula','Vrishchika','Dhanus','Makara','Kumba','Meena','dont know'];
  stars: string[]=['dont know','Aswini','Bharani','krithiga','Rohini','Mrigasira','Arudra','Punarvasu','Pushyami','Aslesha','Magha','Purva Phalguni','Utthara Phalguni','Hastha','Chitra','Swathi','Visakha','Anuradha','Jyesta','Moola','Poorvashada','Uttharashada','Sravana','Dhanista','Sathabhisha','Purva Bhadra','Uttharabhadra','Revathi'];
  padams: string[]=['dont know','Ist Padam','2nd Padam','3rd Padam','4th Padam'];
  complexions: string[]=['Black','Good Looking','Wheatish','Fair','Very Fair'];
  horoscopeMatches: string[]=['Must','Not Required','Not Particular'];
 
  maritalStatuses: string[]=['Married','UnMarried','Divorcee-issues','Divorcee-noissues','Widower','Widow'];
  childrenList: string[]=['none','1','2','3','4','5','6','7','8','9','10'];
  physicalStatuses: string[]=['Normal','Mentally Retarded','Physically Handicapped'];
  contactRelations:string[]=['Self','Friend','Relative','Cousin','Father','Mother','Brother','Sister','Well-Wisher'];
  enteredByList:string[]=['Self','Friend','Relative','Cousin','Father','Mother','Brother','Sister','Well-Wisher'];
  familyStatuses:string[]=['Poor','Lower Middle Class','Middle Class','Upper Middle Class','Rich','Very Rich'];
	brothersNos:string[]=['None','1','2','3','4','5','6','7','8','9','10'];
	sistersNos:string[]=['None','1','2','3','4','5','6','7','8','9','10'];
  salaryList:string[]=['< 10,000','10,000-20,000','20,000-30,000','30,000-40,000','40,000-50,000','50,000-60,000','60,000-70,000','70,000-80,000','80,000-90,000',
  '90,000-1 lakh','1 lakh-2 lakhs','2 lakhs-3 lakhs','3 lakhs-4 lakhs','4 lakhs-5 lakhs','5 lakhs-6 lakhs','6 lakhs-7 lakhs','7 lakhs-8 lakhs','8 lakhs-9 lakhs','9 lakhs-10 lakhs',
  '10 lakhs-20 lakhs','20 lakhs-30 lakhs','30 lakhs-40 lakhs','40 lakhs-50 lakhs','50 lakhs-60 lakhs','60 lakhs-70 lakhs','70 lakhs-80 lakhs','80 lakhs-90 lakhs','90 lakhs-1 Crore','>1 Crore'];

  ngOnInit() {
  }
  onNoClick(): void {
    this.memberService.result="cancel";
    this.dialogRef.close();
  }
  createMember(): void {
    this.member=this.formData.value;
    this.memberService.addMember(this.member)
        .subscribe( data => {
          console.log(data);
          if(data!=null && data!=undefined ){ 
            this.memberService.result="success";
            this.memberService.dialogData=data;
            alert("Member created successfully.");
          }else{
            this.memberService.result="failure";
            alert("Could not create the Member..");
          }
          this.dialogRef.close();
        });

  }
}
*/