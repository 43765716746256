
import {Component, OnInit, AfterViewInit } from '@angular/core';
import {ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort} from '@angular/material';
import {MatTableDataSource} from '@angular/material';
import {SchemeInfo } from '../models/SchemeInfo';
import {SchemesService } from '../services/schemes.service';


@Component({
  selector: 'app-schemes',
  templateUrl: './schemes.component.html',
  styleUrls: ['./schemes.component.css']
})
export class SchemesComponent implements AfterViewInit {
  displayedColumns = ['scheme','amountCharged','maxContactsCanView', 'noOfMonths'];
  dataSource = new MatTableDataSource();
  exampleDatabase: SchemesService | null;
  schemesList: SchemeInfo[]=new Array<SchemeInfo>();
  index: number;
  id: number;
  constructor(private schemesService:SchemesService) { }
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  ngOnInit() {
    console.log("from list component");
    
   
    this.schemesService.getSchemes().subscribe(
      data => {
        console.log(data);
        this.dataSource.data = data;
        this.schemesList=data;
      }
    );
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
 
}


