export class AdminInfo{
		public loginId:string;
		 public	 memberShipType :string;
		 public   dateLastAccessed:Date;
		 public   dateOfPayment:Date;
		 public    dateOfValidity:Date;
		 public    currentAmountPaid:number;
		 public     totalAmountPaid:number;
		 public	 amountCharged:number;
	//	 schemeId:number;
	public    scheme:string;
		 public    noOfTimesPaid:number;
		 public    noOfContactsViewed:number;
		 public    noOfContactsCanView:number;
		 public    noOfTimesAccessed:number;
		 public     timeOfLastAccess:string;
}