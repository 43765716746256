import { Component, OnInit } from '@angular/core';
import { Member } from '../../../models/Member';
import { MemberService } from '../../../services/member.service';
import { DataService} from '../../../services/data.service';
import { LoginService } from '../../../services/login.service';
import { EditMemberService } from '../../../services/edit-member.service';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Inject} from '@angular/core';
import { Gothram } from '../../../models/Gothram';
import { Subsect } from '../../../models/Subsect';
import { Language } from '../../../models/Language';
import { Education } from '../../../models/Education';
import { Profession } from '../../../models/Profession';
import  { DomSanitizer } from '@angular/platform-browser';
import { PhotoInfo } from '../../../models/PhotoInfo';
import { HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-edit-member',
  templateUrl: './edit-member.component.html',
  styleUrls: ['./edit-member.component.css']
})
export class EditMemberComponent implements OnInit {
 // member:Member=this.loginService.loginDetails.member;
  image:any;
  success=false;
  //photoInfo=this.member.photoInfo;
  photoInfo:PhotoInfo[]=[];
  selectedFiles: FileList;
  urls:any[]=[];
  err=false;
  errorMessage:string="could not save the record";
  message:string;
  gothrams: Gothram[];
  subsects: Subsect[];
  motherTongues: Language[];    
  languages: Language[];
  educations: Education[];
  professions: Profession[];
 
  heights: string[]=['below 4 feet','4 feet','4 feet 1 inche','4 feet 2 inches','4 feet 3 inches','4 feet 4 inches','4 feet 5 inches','4 feet 6 inches','4 feet 7 inches','4 feet 8 inches','4 feet 9 inches','4 feet 10 inches','4 feet 11 inches','5 feet','5 feet 1 inche','5 feet 2 inches','5 feet 3 inches','5 feet 4 inches','5 feet 5 inches','5 feet 6 inches','5 feet 7 inches','5 feet 8 inches','5 feet 9 inches','5 feet 10 inches','5 feet 11 inches','6 feet','above 6 feet'];
  weights: string[]=['below 40 kgs ','40 kgs','41 Kgs','42 Kgs','43 Kgs','44 Kgs','45 Kgs','46 Kgs','47 Kgs','48 Kgs','49 Kgs','50 Kgs','51 Kgs','52 Kgs','53 Kgs','54 Kgs','55 Kgs','56 Kgs','57 Kgs','58 Kgs','59 Kgs','60 Kgs','61 Kgs','62 Kgs','63 Kgs','64 Kgs','65 Kgs','66 Kgs','67 Kgs','68 Kgs','69 Kgs','70 Kgs','71 Kgs','72 Kgs','73 Kgs','74 Kgs','75 Kgs','76 Kgs','77 Kgs','78 Kgs','79 Kgs','80 Kgs','above 80 kgs'];
  rasis: string[]=['Mesha','Vrishabha','Mithuna','Karkataka','Simha','Kanya','Tula','Vrishchika','Dhanus','Makara','Kumba','Meena','dont know'];
  stars: string[]=['dont know','Aswini','Bharani','krithiga','Rohini','Mrigasira','Arudra','Punarvasu','Pushyami','Aslesha','Magha','Purva Phalguni','Utthara Phalguni','Hastha','Chitra','Swathi','Visakha','Anuradha','Jyesta','Moola','Poorvashada','Uttharashada','Sravana','Dhanista','Sathabhisha','Purva Bhadra','Uttharabhadra','Revathi'];
  padams: string[]=['dont know','Ist Padam','2nd Padam','3rd Padam','4th Padam'];
  complexions: string[]=['Black','Good Looking','Wheatish','Fair','Very Fair'];
  horoscopeMatches: string[]=['Must','Not Required','Not Particular'];
 
  maritalStatuses: string[]=['Married','UnMarried','Divorcee-issues','Divorcee-noissues','Widower','Widow'];
  childrenList: string[]=['none','1','2','3','4','5','6','7','8','9','10'];
  physicalStatuses: string[]=['Normal','Mentally Retarded','Physically Handicapped'];
  contactRelations:string[]=['Self','Friend','Relative','Cousin','Father','Mother','Brother','Sister','Well-Wisher'];
  enteredByList:string[]=['Self','Friend','Relative','Cousin','Father','Mother','Brother','Sister','Well-Wisher'];
  familyStatuses:string[]=['Poor','Lower Middle Class','Middle Class','Upper Middle Class','Rich','Very Rich'];
	brothersNos:string[]=['None','1','2','3','4','5','6','7','8','9','10'];
	sistersNos:string[]=['None','1','2','3','4','5','6','7','8','9','10'];
  salaryList:string[]=['< 10,000','10,000-20,000','20,000-30,000','30,000-40,000','40,000-50,000','50,000-60,000','60,000-70,000','70,000-80,000','80,000-90,000',
  '90,000-1 lakh','1 lakh-2 lakhs','2 lakhs-3 lakhs','3 lakhs-4 lakhs','4 lakhs-5 lakhs','5 lakhs-6 lakhs','6 lakhs-7 lakhs','7 lakhs-8 lakhs','8 lakhs-9 lakhs','9 lakhs-10 lakhs',
  '10 lakhs-20 lakhs','20 lakhs-30 lakhs','30 lakhs-40 lakhs','40 lakhs-50 lakhs','50 lakhs-60 lakhs','60 lakhs-70 lakhs','70 lakhs-80 lakhs','80 lakhs-90 lakhs','90 lakhs-1 Crore','>1 Crore'];

  constructor(public dialogRef: MatDialogRef<EditMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public member: Member,private editMemberService:EditMemberService,private loginService: LoginService,private dataService: DataService, public memberService: MemberService,private sanitizer: DomSanitizer) { }
  
  getPhoto(){
    this.memberService.getPhoto(this.member.loginInfo.loginId).subscribe(photoInfo=>{
      this.success=true;
      this.photoInfo=photoInfo;
      this.editMemberService.photoInfo=photoInfo;
      console.log("from getPhoto...");
      console.log("photoInfo="+JSON.stringify(photoInfo));
      let objectURL = 'data:'+photoInfo[0].mimeType+';base64,' + photoInfo[0].photoContent;       
      console.log("objectURL="+JSON.stringify(objectURL));
      this.urls[0] = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      console.log("this.image="+JSON.stringify(this.image));
    },(error:HttpErrorResponse)=>{
      this.err=true;
      console.log(error);
      if(!error.error.errors==undefined){
        this.errorMessage=error.error.errors[0].defaultMessage;
          console.log (error.error.errors[0].defaultMessage);
      }
    });
  }
  updateMember(): void {
    console.log("member.id="+this.member.id);
    this.member.photoInfo=this.editMemberService.photoInfo;
    if(this.member.detailedInfo.maritalStatus=="UnMarried"){
      this.member.detailedInfo.noOfChildren="0";
    }
    if(this.member.detailedInfo.illHealth=='no'){
      this.member.detailedInfo.typeOfIllHealth="";
    }

    this.memberService.updateMember(this.member)
        .subscribe(
           data => {
          this.err=false;
          console.log(data);
          if(data!=null && data!=undefined ){
            this.editMemberService.result="success";
            alert("User updated successfully.");
          }else{
            
            this.editMemberService.result="failure";
            alert("Could not update the user..");
          }
         // this.dialogRef.close();
        },(error: HttpErrorResponse) => {
          this.err=true;
          console.log(error);
          if(!error.error.errors==undefined){
            this.errorMessage=error.error.errors[0].defaultMessage;
              console.log (error.error.errors[0].defaultMessage);
          }
    }
      
      );

  };

  ngOnInit() {
    
    this.dataService.getGothrams().subscribe(
      data => {
        console.log(data);
        this.gothrams=data;
      }
    );
    this.dataService.getSubsects().subscribe(
      data => {
        console.log(data);
        this.subsects=data;
      }
    );
    this.dataService.getEducations().subscribe(
      data => {
        console.log(data);
        this.educations=data;
      }
    );
    this.dataService.getProfessions().subscribe(
      data => {
        console.log(data);
        this.professions=data;
      }
    );
    this.dataService.getLanguages().subscribe(
      data => {
        console.log(data);
        this.languages=data;
        this.motherTongues=data;
      }
    );

   
  }
  onSelectFile(event,currentIndex) {
      this.message="";
      console.log("from selectFile");
      console.log("event.target.files="+JSON.stringify(event.target.files));
      console.log("currentIndex="+currentIndex);
      this.selectedFiles = event.target.files;
      if (event.target.files && event.target.files[0]) {
        var filesLength = event.target.files.length;
        for (let i = 0; i < filesLength; i++) {
                var reader = new FileReader();
                reader.onload = (event) => {
                    console.log(event.target);
                    console.log("reader.result="+JSON.stringify(reader.result));
                    this.urls[currentIndex]="";
                    this.urls[currentIndex]=reader.result;
                    console.log("urls["+currentIndex+"]="+this.urls[currentIndex]); 
                    
                 //   this.filesList[currentIndex]=this.selectedFiles.item(0);
                        var file=this.selectedFiles.item(0);
                        console.log("urls[currentIndex]="+this.urls[currentIndex]);
                        var index=this.urls[currentIndex].indexOf(',');
                        var photoContent=this.urls[currentIndex].substr(index+1);
                        console.log("photoContent="+photoContent);
                        console.log("photoContent.length="+photoContent.length);
                        if(photoContent.length<6000){
                            var photoInfo=new PhotoInfo();
                            photoInfo.photoName=file.name;
                            photoInfo.mimeType=file.type;
                            photoInfo.photoContent=photoContent;
                    
                            this.photoInfo[currentIndex]=photoInfo;
                            this.editMemberService.photoInfo=this.photoInfo;
                         //   this.member.photoInfo=this.photoInfo; 
                        }else{
                          this.photoInfo[currentIndex]=new PhotoInfo();
                          this.urls[currentIndex]="";
                          this.message="The Size of the Photo cannot be greater than 5KB";
                        } 
                  }
                reader.readAsDataURL(event.target.files[i]);
        }
      }
    }    

}

/*import { Component, OnInit,ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Inject} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Member } from '../../../models/Member';
import { MemberService } from '../../../services/member.service';
import {DataService} from '../../../services/data.service';
import { Gothram } from '../../../models/Gothram';
import { Subsect } from '../../../models/Subsect';
import { Language } from '../../../models/Language';
import { Education } from '../../../models/Education';
import { Profession } from '../../../models/Profession';

@Component({
  selector: 'app-edit-member',
  templateUrl: './edit-member.component.html',
  styleUrls: ['./edit-member.component.css']
})
export class EditMemberComponent implements OnInit {
    
  err=false;
  errorMessage:string="could not save the record";
  gothrams: Gothram[];
  subsects: Subsect[];
  motherTongues: Language[];    
  languages: Language[];
  educations: Education[];
  professions: Profession[];
 
  heights: string[]=['below 4 feet','4 feet','4 feet 1 inche','4 feet 2 inches','4 feet 3 inches','4 feet 4 inches','4 feet 5 inches','4 feet 6 inches','4 feet 7 inches','4 feet 8 inches','4 feet 9 inches','4 feet 10 inches','4 feet 11 inches','5 feet','5 feet 1 inche','5 feet 2 inches','5 feet 3 inches','5 feet 4 inches','5 feet 5 inches','5 feet 6 inches','5 feet 7 inches','5 feet 8 inches','5 feet 9 inches','5 feet 10 inches','5 feet 11 inches','6 feet','above 6 feet'];
  weights: string[]=['below 40 kgs ','40 kgs','41 Kgs','42 Kgs','43 Kgs','44 Kgs','45 Kgs','46 Kgs','47 Kgs','48 Kgs','49 Kgs','50 Kgs','51 Kgs','52 Kgs','53 Kgs','54 Kgs','55 Kgs','56 Kgs','57 Kgs','58 Kgs','59 Kgs','60 Kgs','61 Kgs','62 Kgs','63 Kgs','64 Kgs','65 Kgs','66 Kgs','67 Kgs','68 Kgs','69 Kgs','70 Kgs','71 Kgs','72 Kgs','73 Kgs','74 Kgs','75 Kgs','76 Kgs','77 Kgs','78 Kgs','79 Kgs','80 Kgs','above 80 kgs'];
  rasis: string[]=['Mesha','Vrishabha','Mithuna','Karkataka','Simha','Kanya','Tula','Vrishchika','Dhanus','Makara','Kumba','Meena','dont know'];
  stars: string[]=['dont know','Aswini','Bharani','krithiga','Rohini','Mrigasira','Arudra','Punarvasu','Pushyami','Aslesha','Magha','Purva Phalguni','Utthara Phalguni','Hastha','Chitra','Swathi','Visakha','Anuradha','Jyesta','Moola','Poorvashada','Uttharashada','Sravana','Dhanista','Sathabhisha','Purva Bhadra','Uttharabhadra','Revathi'];
  padams: string[]=['dont know','Ist Padam','2nd Padam','3rd Padam','4th Padam'];
  complexions: string[]=['Black','Good Looking','Wheatish','Fair','Very Fair'];
  horoscopeMatches: string[]=['Must','Not Required','Not Particular'];
 
  maritalStatuses: string[]=['Married','UnMarried','Divorcee-issues','Divorcee-noissues','Widower','Widow'];
  childrenList: string[]=['none','1','2','3','4','5','6','7','8','9','10'];
  physicalStatuses: string[]=['Normal','Mentally Retarded','Physically Handicapped'];
  contactRelations:string[]=['Self','Friend','Relative','Cousin','Father','Mother','Brother','Sister','Well-Wisher'];
  enteredByList:string[]=['Self','Friend','Relative','Cousin','Father','Mother','Brother','Sister','Well-Wisher'];
  familyStatuses:string[]=['Poor','Lower Middle Class','Middle Class','Upper Middle Class','Rich','Very Rich'];
	brothersNos:string[]=['None','1','2','3','4','5','6','7','8','9','10'];
	sistersNos:string[]=['None','1','2','3','4','5','6','7','8','9','10'];
  salaryList:string[]=['< 10,000','10,000-20,000','20,000-30,000','30,000-40,000','40,000-50,000','50,000-60,000','60,000-70,000','70,000-80,000','80,000-90,000',
  '90,000-1 lakh','1 lakh-2 lakhs','2 lakhs-3 lakhs','3 lakhs-4 lakhs','4 lakhs-5 lakhs','5 lakhs-6 lakhs','6 lakhs-7 lakhs','7 lakhs-8 lakhs','8 lakhs-9 lakhs','9 lakhs-10 lakhs',
  '10 lakhs-20 lakhs','20 lakhs-30 lakhs','30 lakhs-40 lakhs','40 lakhs-50 lakhs','50 lakhs-60 lakhs','60 lakhs-70 lakhs','70 lakhs-80 lakhs','80 lakhs-90 lakhs','90 lakhs-1 Crore','>1 Crore'];


  
 
  


  constructor(public dialogRef: MatDialogRef<EditMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public member: Member,private dataService: DataService, public memberService: MemberService) { }

  onNoClick(): void {
    this.memberService.result="cancel";
    this.dialogRef.close();
  }
  
  updateMember(): void {
  //  console.log("member.id="+this.member.id);
    this.memberService.updateMember(this.member)
        .subscribe( data => {
       //   console.log(data);
          if(data!=null && data!=undefined ){
            this.memberService.result="success";
            alert("User updated successfully.");
          }else{
            this.memberService.result="failure";
            alert("Could not update the user..");
          }
          this.dialogRef.close();
        });

  };

  ngOnInit() {
    this.dataService.getGothrams().subscribe(
      data => {
      //  console.log(data);
        this.gothrams=data;
      }
    );
    this.dataService.getSubsects().subscribe(
      data => {
     //   console.log(data);
        this.subsects=data;
      }
    );
    this.dataService.getEducations().subscribe(
      data => {
      //  console.log(data);
        this.educations=data;
      }
    );
    this.dataService.getProfessions().subscribe(
      data => {
     //   console.log(data);
        this.professions=data;
      }
    );
    this.dataService.getLanguages().subscribe(
      data => {
     //   console.log(data);
        this.languages=data;
        this.motherTongues=data;
      }
    );

  }

}*/


