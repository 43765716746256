import { Component, OnInit } from '@angular/core';
import { LoginDetails } from '../models/LoginDetails';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {
loginDetails :LoginDetails=this.loginService.loginDetails;
  constructor(private loginService:LoginService) { }

  ngOnInit() {
  }

}
