import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { LoginDetails } from '../../models/LoginDetails';
@Component({
  selector: 'app-main-menu-bar',
  templateUrl: './main-menu-bar.component.html',
  styleUrls: ['./main-menu-bar.component.css']
})
export class MainMenuBarComponent implements OnInit {
    role:string=this.loginService.loginDetails.role;
  
  constructor(private loginService:LoginService) { }

  ngOnInit() {
    
  }

}
