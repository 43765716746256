import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoginInfo } from '../models/LoginInfo';
import { LoginDetails } from '../models/LoginDetails';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
//httpOptions.headers.append('Access-Control-Allow-Origin', 'http://localhost:4200');
//httpOptions.headers.append('Access-Control-Allow-Origin', 'http://www.dwaithamatrimony.com');
httpOptions.headers.append('Access-Control-Allow-Origin', environment.accessControlAllowOrginUrl);
httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
httpOptions.headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loginInfo:LoginInfo;
  loginDetails:LoginDetails;
  constructor(private http:HttpClient) { }
  //private loginUrl = 'login';
 // private loginUrl = 'http://localhost:8080/login';
 // private loginUrl = 'http://www.dwaithamatrimony.com/login';
 private loginUrl =environment.loginUrl;
  public checkLoginInfo(loginInfo:LoginInfo) {
       return this.http.post<LoginDetails>(this.loginUrl+'/checkLoginInfo',loginInfo,httpOptions);
  }
}

