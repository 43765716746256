import { AddressInfo } from "./AddressInfo";
import { LoginInfo } from "./LoginInfo";
import { PhoneInfo } from "./PhoneInfo";
import { PhotoInfo } from "./PhotoInfo";
import { OfficeInfo } from "./OfficeInfo";
import { FamilyInfo } from "./FamilyInfo";
import { AdminInfo } from "./AdminInfo";
import { SmsInfo } from "./SmsInfo";
import { BasicInfo } from "./BasicInfo";
import { DetailedInfo } from "./DetailedInfo";

export class Member{
    public  id: number;
    public  basicInfo:BasicInfo;
    public  detailedInfo:DetailedInfo;
    public  familyInfo:FamilyInfo;
    public  addressInfo :AddressInfo;
    public  phoneInfo: PhoneInfo;
    public  officeInfo:OfficeInfo;
    public  photoInfo:PhotoInfo[];
    public  adminInfo:AdminInfo;
    public  loginInfo: LoginInfo;
    public  smsInfo:SmsInfo=new SmsInfo();
}