import { Component, OnInit,ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { LoginInfo } from '../models/LoginInfo';
import { LoginService } from '../services/login.service';
import { Router} from "@angular/router";
import { isNullOrUndefined } from 'util';
import { LoginDetails } from '../models/LoginDetails';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginInfo :LoginInfo =new LoginInfo();
  
  @ViewChild("f") formData :NgForm;
  found=true;
  constructor(private loginService :LoginService, private router: Router) { }

  ngOnInit() {
  }
  checkLoginDetails(){
    this.loginInfo=this.formData.value;
 //   console.log("loginInfo=");
 //   console.log(this.loginInfo);

    this.loginService.checkLoginInfo(this.loginInfo)
    .subscribe(
        data => {
           //   console.log(data);
                var loginDetails = data;
              if( loginDetails!=undefined){
                this.found=true;
                this.loginService.loginDetails=loginDetails;
         //       console.log("inside data==true");
           //     console.log("loginDetails");
             //   console.log(loginDetails);
                this.router.navigate(['main-page']);
              }else{
                this.found=false;
               // alert("Wrong UserName or Password");  
              }
          
        },err => {
          this.found=false;
              console.log(err);
             // alert("Wrong UserName or Password");
        }
      );
   }
}
