import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AdminDetailsComponent } from './main-page/admin-details/admin-details.component';
import { MainPageComponent } from './main-page/main-page.component';
import { RegisterComponent} from './register/register.component';
import { AboutUsComponent} from './about-us/about-us.component';
import { PaymentDetailsComponent} from './payment-details/payment-details.component';
import { SchemesComponent} from './schemes/schemes.component';
import { ContactDetailsComponent} from './contact-details/contact-details.component';
import { DonationsComponent} from './donations/donations.component';
import { PostACommentComponent} from './post-a-comment/post-a-comment.component';
import { ProcedureComponent} from './procedure/procedure.component';
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { AddMemberComponent } from './main-page/users/add-member/add-member.component';
import { EditMemberComponent } from './main-page/users/edit-member/edit-member.component';
import { ViewMemberComponent } from './main-page/users/view-member/view-member.component';
import { EditLoggedMemberComponent } from './main-page/users/edit-logged-member/edit-logged-member.component';
import { ViewLoggedMemberComponent } from './main-page/users/view-logged-member/view-logged-member.component';

import { ListMembersComponent } from './main-page/users/list-members/list-members.component';
import { ChangePasswordComponent } from './main-page/users/change-password/change-password.component';
import { LogOutComponent } from './main-page/log-out/log-out.component';
const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'procedure', component: ProcedureComponent },
    { path: 'login', component: LoginComponent },
    { path: 'main-page', component: MainPageComponent },
    { path: 'register-success', component: RegisterSuccessComponent },
    { path: 'about', component: AboutUsComponent },   
    { path: 'payment', component: PaymentDetailsComponent },
    { path: 'schemes', component: SchemesComponent },
    { path: 'donations', component: DonationsComponent },
    { path: 'contact', component: ContactDetailsComponent },
    { path: 'postacomment', component: PostACommentComponent },
    { path: 'add-member', component: AddMemberComponent },
    { path: 'edit-member', component: EditMemberComponent },
    { path: 'view-member', component: ViewMemberComponent },
    { path: 'edit-logged-member', component: EditLoggedMemberComponent },
    { path: 'view-logged-member', component: ViewLoggedMemberComponent },
    { path: 'admin-details', component: AdminDetailsComponent },
    { path: 'list-members', component: ListMembersComponent },
    { path: 'change-password', component: ChangePasswordComponent },
    { path: 'log-out', component: LogOutComponent }
  ];

  export const routing =RouterModule.forRoot(routes);