import { Injectable } from '@angular/core';
import { SchemeInfo } from '../models/SchemeInfo';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
//httpOptions.headers.append('Access-Control-Allow-Origin', 'http://localhost:8080');
//httpOptions.headers.append('Access-Control-Allow-Origin', 'http://www.dwaithamatrimony.com');
httpOptions.headers.append('Access-Control-Allow-Origin', environment.accessControlAllowOrginUrl);
httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
httpOptions.headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');

@Injectable({
  providedIn: 'root'
})
export class SchemesService {
 
 // private  schemesUrl = 'http://localhost:8080/schemes';
  //private  schemesUrl = 'http://www.dwaithamatrimony.com/schemes';
  private  schemesUrl = environment.schemesUrl;
 constructor (private http: HttpClient) {}
 
 getSchemeBySchemeName(scheme){
  return this.http.get<SchemeInfo>(this.schemesUrl+"/getSchemeBySchemeName/"+scheme,httpOptions);

 }
 getSchemes():Observable<SchemeInfo[]> {
   return this.http.get<SchemeInfo[]>(this.schemesUrl,httpOptions);
 }

  
}
