import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdminInfo } from '../models/AdminInfo';
import { LoginDetails } from '../models/LoginDetails';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
//httpOptions.headers.append('Access-Control-Allow-Origin', 'http://localhost:8080');
//httpOptions.headers.append('Access-Control-Allow-Origin', 'http://www.dwaithamatrimony.com');
httpOptions.headers.append('Access-Control-Allow-Origin', environment.accessControlAllowOrginUrl);
httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
httpOptions.headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');

@Injectable({
  providedIn: 'root'
})
export class AdminService {

//   private pacUrl = 'postacomment';
// private pacUrl = 'http://localhost:8080/admin';
// private pacUrl = 'http://www.dwaithamatrimony.com/admin';
  private adminUrl=environment.adminUrl;
 constructor(private http:HttpClient) { }

 public createAdminDetails(adminDetails) {
   console.log("from service");
   console.log(adminDetails);
    return this.http.post<AdminInfo>(this.adminUrl+"/createAdminDetails",adminDetails ,httpOptions);
 }
 public showContactDetails(loginDetails) {
  console.log("from service");
  console.log(loginDetails);
   return this.http.post<LoginDetails>(this.adminUrl+"/showContactDetails",loginDetails ,httpOptions);
 }
}
