import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-register-success',
  templateUrl: './register-success.component.html',
  styleUrls: ['./register-success.component.css']
})
export class RegisterSuccessComponent implements OnInit {
  loginId:string;
  password:string;
  constructor(private loginService :LoginService) { }

  ngOnInit() {
    this.loginId=this.loginService.loginInfo.loginId;
    this.password=this.loginService.loginInfo.password;
  }

}
