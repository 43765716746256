import { ViewChild,Component, OnInit } from '@angular/core';
import { PostAComment } from '../models/PostAComment';
import { NgForm } from '@angular/forms';
import { PostACommentService } from '../services/post-acomment.service';
import { Router} from "@angular/router";

@Component({
  selector: 'app-post-a-comment',
  templateUrl: './post-a-comment.component.html',
  styleUrls: ['./post-a-comment.component.css']
})
export class PostACommentComponent implements OnInit {
  postAComment: PostAComment = new PostAComment();
  @ViewChild("f") formData :NgForm;

 constructor(private pacService:PostACommentService,private router: Router){}
  ngOnInit() {
  }
  saveComment(): void {
    this.postAComment=this.formData.value;
    console.log(this.postAComment);
    this.pacService.createPostAComment(this.postAComment)
        .subscribe( data => {
          console.log(data);
          if(data!=null && data!=undefined ){ 
            console.log("Comment created successfully.");
            this.router.navigate(['']);
          }else{
            console.log("Could not create the comment..");
          }
          
        });

  }
}
