import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
//exportAs:'appHideTab'
@Directive({
  selector: '[hideTab]'
  
})
export class HideTabDirective  {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }
    
    @Input() set hideTab(condition: boolean) {
        if (!condition) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();        }
    }
}
