import { Component, OnInit, ViewChild } from '@angular/core';
//import {  Input, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
//import { HttpClient, HttpResponse, HttpRequest,HttpEventType } from '@angular/common/http';
import { PhotoInfo } from '../../models/PhotoInfo';
import { MemberService } from '../../services/member.service';
import { RegisterService } from '../../services/register.service';
//import { Subscription } from 'rxjs/Subscription';
//import { of } from 'rxjs/observable/of';
//import { catchError, last, map, tap } from 'rxjs/operators';
//import { HttpErrorResponse} from '@angular/common/http';
import  { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-photo-details',
  templateUrl: './photo-details.component.html',
  styleUrls: ['./photo-details.component.css'],
  animations: [
    trigger('fadeInOut', [
          state('in', style({ opacity: 100 })),
          transition('* => void', [
                animate(300, style({ opacity: 0 }))
          ])
    ])
  ]
})
export class PhotoDetailsComponent implements OnInit {

  err=false;
  errorMessage:string="";
  photoInfo:PhotoInfo[]=[];
  message:string;
  selectedFiles: FileList;
  currentFileUpload: File;
  filesList:File[]=[];
  progress: { percentage: number } = { percentage: 0 };
  image:any;
  urls:any[]=[];

constructor(private registerService:RegisterService,private memberService: MemberService,private sanitizer: DomSanitizer) { }

  ngOnInit() {}
  //to upload single photo file with spring boot MultipartFile  at backend
  //sends the file uploaded to back end and spring boot back end 
  //returns the photoInfo object populated with photo info.
 /* upload() {
        this.progress.percentage = 0;
        this.currentFileUpload = this.selectedFiles.item(0);
        console.log("currentFileUpload="+JSON.stringify(this.currentFileUpload));
        this.memberService.pushFileToStorage(this.currentFileUpload).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
            console.log('File is completely uploaded!');
            console.log("event="+JSON.stringify(event.body));
            this.memberService.photoInfo=event.body;
            this.photoInfo=event.body;
            
            let objectURL = 'data:'+this.photoInfo.mimetype+';base64,' + this.photoInfo.photoContent;       
            this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        }
      });
      this.selectedFiles = undefined;
  }*/
  //to upload multiple photo files with spring boot MultipartFile list at backend
  //sends the files list uploaded to back end and spring boot back end 
  //returns the photoInfo object populated with photo info.
  /*upload() {
    this.progress.percentage = 0;
    console.log("filesList="+JSON.stringify(this.filesList));
    this.memberService.pushFileToStorage(this.filesList).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
            this.progress.percentage = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
            console.log('File is completely uploaded!');
            console.log("event="+JSON.stringify(event.body));
            this.memberService.photoInfo=event.body;
            this.photoInfo=event.body;
            
        }
    });
  
  }*/
  
  onSelectFile(event,currentIndex) {
      this.message="";
      console.log("from selectFile");
      console.log("event.target.files="+JSON.stringify(event.target.files));
      console.log("currentIndex="+currentIndex);
      this.selectedFiles = event.target.files;
        if (event.target.files && event.target.files[0]) {
          var filesLength = event.target.files.length;
          for (let i = 0; i < filesLength; i++) {
                  var reader = new FileReader();
                  reader.onload = (event) => {
                      console.log(event.target);
                      console.log("reader.result="+JSON.stringify(reader.result));
                      this.urls[currentIndex]="";
                      this.urls[currentIndex]=reader.result;
                      console.log("urls["+currentIndex+"]="+this.urls[currentIndex]); 
                      
                      this.filesList[currentIndex]=this.selectedFiles.item(0);
                      var file=this.selectedFiles.item(0);
                      console.log("urls[currentIndex]="+this.urls[currentIndex]);
                      var index=this.urls[currentIndex].indexOf(',');
                      var photoContent=this.urls[currentIndex].substr(index+1);
                      console.log("photoContent="+photoContent);
                    if(photoContent.length<6000){
                      var photoInfo=new PhotoInfo();
                      photoInfo.photoName=file.name;
                      photoInfo.mimeType=file.type;
                      photoInfo.photoContent=photoContent;
              
                      this.photoInfo[currentIndex]=photoInfo;
                      this.registerService.photoInfo=this.photoInfo; 
                      //this.member.photoInfo=this.photoInfo; 
                    }else{
                      this.photoInfo[currentIndex]=new PhotoInfo();
                      this.urls[currentIndex]="";
                      this.message="The Size of the Photo cannot be greater than 5KB";
                    }
                      
                  }
                  reader.readAsDataURL(event.target.files[i]);
          }
        }
          
  }
}
