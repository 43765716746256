import { Component, OnInit ,ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { SchemesService } from '../../services/schemes.service';
import { Router} from "@angular/router";
import { AdminInfo } from '../../models/AdminInfo';
import { HttpErrorResponse} from '@angular/common/http';
import { SchemeInfo } from '../../models/SchemeInfo';

@Component({
  selector: 'app-admin-details',
  templateUrl: './admin-details.component.html',
  styleUrls: ['./admin-details.component.css']
})
export class AdminDetailsComponent implements OnInit {
  @ViewChild("f") formData:NgForm;
  adminInfo:AdminInfo =new AdminInfo();
  err=false;
  errorMessage:string="could not save the record";
  schemeInfo :SchemeInfo=new SchemeInfo();
  schemes :SchemeInfo[];

  constructor( private router: Router,private adminService: AdminService,private schemeService: SchemesService) {}
  onSchemeSelect(scheme){
    console.log(scheme);
    this.schemeService.getSchemeBySchemeName(scheme).subscribe(data=>{
      console.log(data);
      this.schemeInfo=data;
      console.log("scheme=");
      console.log(this.schemeInfo);
    });
  }

  saveAdminDetails(){
    console.log(this.formData);
    this.adminInfo=this.formData.value;
      console.log("adminInfo");
      console.log(this.adminInfo);
      
      this.adminService.createAdminDetails(this.adminInfo)
          .subscribe( data => {
            console.log("data=");
            console.log(data);
            if(data!=null && data!=undefined ){ 
              alert("admin details created successfully.");
            }else{
              alert("Could not create the Member..");
            }
            
          },(error: HttpErrorResponse) => {
                  this.err=true;
                  console.log(error);
                  if(!error.error.errors==undefined){
                    this.errorMessage=error.error.errors[0].defaultMessage;
                      console.log (error.error.errors[0].defaultMessage);
                  }
            }
        
        );
  
   }








  ngOnInit() {
    this.schemeService.getSchemes().subscribe(data=>{
      this.schemes=data;
    });
  }

}
