import { Injectable } from '@angular/core';
import { PhoneInfo } from '../models/PhoneInfo';
import { AddressInfo } from '../models/AddressInfo';
import { FamilyInfo } from '../models/FamilyInfo';
import { OfficeInfo } from '../models/OfficeInfo';
import { PhotoInfo } from '../models/PhotoInfo';

@Injectable({
  providedIn: 'root'
})
export class AddMemberService {
  result;
  phoneInfo:PhoneInfo=new PhoneInfo();
  addressInfo:AddressInfo=new AddressInfo();
  photoInfo:PhotoInfo[]=[];
  familyInfo:FamilyInfo=new FamilyInfo();
  officeInfo:OfficeInfo=new OfficeInfo();
  constructor() { }
}
