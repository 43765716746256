import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { Member } from '../../../models/member';
import  { DomSanitizer } from '@angular/platform-browser';
import { MemberService } from '../../../services/member.service';
import { HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-view-logged-member',
  templateUrl: './view-logged-member.component.html',
  styleUrls: ['./view-logged-member.component.css']
})
export class ViewLoggedMemberComponent implements OnInit {
  member:Member=this.loginService.loginDetails.member;
  image:any;
  //photoInfo=this.member.photoInfo;
  photoInfo;
  urls:any[]=[];
  err=false;
  success;
  errorMessage;
  constructor(public memberService: MemberService,private loginService :LoginService,private sanitizer: DomSanitizer) { }

  ngOnInit() {
    console.log("photoInfo="+JSON.stringify(this.photoInfo));
      
  }
  getPhoto(){
    this.memberService.getPhoto(this.member.loginInfo.loginId).subscribe(photoInfo=>{
      this.success=true;
      this.photoInfo=photoInfo;
      console.log("from getPhoto...");
      console.log("photoInfo="+JSON.stringify(photoInfo));
      let objectURL = 'data:'+photoInfo[0].mimeType+';base64,' + photoInfo[0].photoContent;       
      console.log("objectURL="+JSON.stringify(objectURL));
      this.urls[0] = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      console.log("this.image="+JSON.stringify(this.image));
    },(error:HttpErrorResponse)=>{
      this.err=true;
      console.log(error);
      if(!error.error.errors==undefined){
        this.errorMessage=error.error.errors[0].defaultMessage;
          console.log (error.error.errors[0].defaultMessage);
      }
    });
  }

}
