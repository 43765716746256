import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable } from 'rxjs';
import { Gothram } from '../models/Gothram';
import { Education } from '../models/Education';
import { Profession } from '../models/Profession';
import { Language } from '../models/Language';
import { Subsect } from '../models/Subsect';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
//httpOptions.headers.append('Access-Control-Allow-Origin', 'http://localhost:4200');
//httpOptions.headers.append('Access-Control-Allow-Origin', 'http://www.dwaithamatrimony.com');
httpOptions.headers.append('Access-Control-Allow-Origin', environment.accessControlAllowOrginUrl);

httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
httpOptions.headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');


@Injectable({
  providedIn: 'root'
})

export class DataService {
 // private  dataUrl = 'data';
 // private  dataUrl = 'http://localhost:8080/data';
 // private  dataUrl = 'http://www.dwaithamatrimony.com/data';
  private  dataUrl =environment.dataUrl;
  constructor (private http: HttpClient) {}
  
  getGothrams():Observable<Gothram[]> {
    return this.http.get<Gothram[]>(this.dataUrl+"/gothrams",httpOptions);
  }
  getSubsects():Observable<Subsect[]> {
    return this.http.get<Subsect[]>(this.dataUrl+"/subsects",httpOptions);
  }
  getEducations():Observable<Education[]> {
    return this.http.get<Education[]>(this.dataUrl+"/educations",httpOptions);
  }
  getProfessions():Observable<Profession[]> {
    return this.http.get<Profession[]>(this.dataUrl+"/professions",httpOptions);
  }
  getLanguages():Observable<Language[]> {
    return this.http.get<Language[]>(this.dataUrl+"/languages",httpOptions);
  }
}