import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Member } from '../models/Member';
import { Observable } from 'rxjs';
//import { NgForm, NgModel,NgModelGroup } from '@angular/forms';
import { PhotoInfo } from '../models/PhotoInfo';
import { LoginInfo } from '../models/LoginInfo';
import { LoginDetails } from '../models/LoginDetails';
import { environment } from '../../environments/environment';
//import { HttpEvent, HttpRequest } from '@angular/common/http';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
//httpOptions.headers.append('Access-Control-Allow-Origin', 'http://localhost:4200');
//httpOptions.headers.append('Access-Control-Allow-Origin', 'http://www.dwaithamatrimony.com');
httpOptions.headers.append('Access-Control-Allow-Origin', environment.accessControlAllowOrginUrl);
httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
httpOptions.headers.append('Access-Control-Allow-Headers', 'X-Requested-With,Content-Type');
httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');


@Injectable({
  providedIn: 'root'
})
export class MemberService {
 
 // private memberUrl = 'http://localhost:8080/members';
 // private photoUrl = 'http://localhost:8080/photoUpload';
  //private photoUrl = 'http://www.dwaithamatrimony.com/photoUpload';
  //private memberUrl = 'http://www.dwaithamatrimony.com/members';
  private photoUrl = environment.photoUrl;
  private memberUrl =environment.memberUrl;
  
  dialogData: any;
  member :Member=new Member();
  
  
  constructor(private http:HttpClient) { }

  getDialogData() {
    return this.dialogData;
  }
  public getMembers(ld:LoginDetails):Observable<Member[]> {
   console.log("from service");
   console.log(ld);
    return this.http.post<Member[]>(this.memberUrl+"/getMembers",ld,httpOptions);
    
  }
  public getPhoto (loginId) {
    console.log("from getPhoto,in member.service,loginId="+loginId);
    
    return this.http.post<PhotoInfo[]>(this.photoUrl +"/getPhoto/"+ loginId, httpOptions);
  }
  public updateMember (member: Member) {
    console.log("from updateMember,in member.service");
    this.dialogData = member;
    return this.http.post<Member>(this.memberUrl +"/updateMember/"+ member.id, member,httpOptions);
  }
  public deleteMember(member) {
      return this.http.delete(this.memberUrl + "/"+ member.id,httpOptions);
  }
  public deleteMembers(membersList) {

    var  httpOptions=  new HttpHeaders().append('Content-Type', 'application/json')
    .append('Access-Control-Allow-Origin',   environment.accessControlAllowOrginUrl)
    .append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE')
    .append('Access-Control-Allow-Headers', 'X-Requested-With,Content-Type,Access-Control-Allow-Origin,Access-Control-Allow-Methods, Access-Control-Allow-Headers');
    console.log("httpOptions="+JSON.stringify(httpOptions));
    console.log("access-control-allow-origin="+environment.accessControlAllowOrginUrl);

    return this.http.post(this.memberUrl + "/deleteMembers/", membersList,
    {
      headers:httpOptions,
      responseType:'text'
    });
  }
 /* public uploadPhoto(file: File) {
    console.log("from service");
    console.log("photoInfo=");
    console.log(JSON.stringify(this.photoInfo));
    console.log(file);
    const formdata: FormData = new FormData();

    formdata.append('file', file);
     return this.http.post<PhotoInfo>(this.photoUrl+"/file/upload",formdata,httpOptions);
  }*/

/*  pushFileToStorage(filesList: File[]): Observable<HttpEvent<PhotoInfo>> {
    const formdata: FormData = new FormData();

    filesList.forEach(function (file) {
      formdata.append('photo', file);
      console.log("file="+file);
    });
    const req = new HttpRequest('POST', this.photoUrl+'/file/upload', formdata, {
      reportProgress: true
      
    });
    return this.http.request(req);
  }*/
  public addMember(member) {
    console.log("from service");
    console.log(member);
     return this.http.post<LoginInfo>(this.memberUrl+"/addMember/",member,httpOptions);
  }
}
