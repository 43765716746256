import { Component, OnInit } from '@angular/core';
import {ConfirmDialogService} from '../../../services/confirm-dialog.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Inject} from '@angular/core';
//import { NgForm } from '@angular/forms';
import { Member } from '../../../models/Member';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public member: Member, public confirmService: ConfirmDialogService) { }

  

  onNoClick(): void {
    this.confirmService.result="no";
    this.dialogRef.close();
  }
  
  onYesClick(): void {
    this.confirmService.result="yes";
    this.dialogRef.close();
  }

  ngOnInit() {
  }

}


