import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PostAComment } from '../models/PostAComment';
import { environment } from '../../environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
//httpOptions.headers.append('Access-Control-Allow-Origin', 'https://localhost:8080');
//httpOptions.headers.append('Access-Control-Allow-Origin', 'http://www.dwaithamatrimony.com');
httpOptions.headers.append('Access-Control-Allow-Origin', environment.accessControlAllowOrginUrl);
httpOptions.headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
httpOptions.headers.append('Access-Control-Allow-Headers', 'X-Requested-With,content-type');
httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');

@Injectable({
  providedIn: 'root'
})
export class PostACommentService {
// private pacUrl = 'postacomment';
 //private pacUrl = 'https://localhost:8080/postacomment';
 //private pacUrl = 'http://www.dwaithamatrimony.com/postacomment';
 private pacUrl =environment.pacUrl;
   constructor(private http:HttpClient) { }

  public createPostAComment(postacomment) {
    console.log("from service");
    console.log(postacomment);
     return this.http.post<PostAComment>(this.pacUrl+"/addComment",postacomment ,httpOptions);
  }
}
