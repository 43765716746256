import { Component, OnInit } from '@angular/core';
import { MemberService } from '../../../services/member.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Inject} from '@angular/core';
import { Member } from '../../../models/Member';
import { LoginDetails } from '../../../models/LoginDetails';
import { LoginService } from '../../../services/login.service';
import { AdminService } from '../../../services/admin.service';
import  { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse} from '@angular/common/http';
import { ViewMemberService } from '../../../services/view-member.service';


@Component({
  selector: 'app-view-member',
  templateUrl: './view-member.component.html',
  styleUrls: ['./view-member.component.css']
})
export class ViewMemberComponent implements OnInit {
  ld:LoginDetails=this.loginService.loginDetails;
  image:any;
  //photoInfo=this.member.photoInfo;

  showContactDetails:boolean=false;
  validMember:boolean;
  message:string;
  photoInfo;
  urls:any[]=[];
  err=false;
  success;
  errorMessage;

  constructor(public dialogRef: MatDialogRef<ViewMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public member: Member, public memberService: MemberService,public loginService: LoginService,public adminService: AdminService,private sanitizer: DomSanitizer,public viewMemberService:ViewMemberService) { }

    onOkClick(): void {
      this.viewMemberService.result="ok";
      this.dialogRef.close();
    }
    getPhoto(){
      this.memberService.getPhoto(this.member.loginInfo.loginId).subscribe(photoInfo=>{
        this.success=true;
        this.photoInfo=photoInfo;
        console.log("from getPhoto...");
        console.log("photoInfo="+JSON.stringify(photoInfo));
        let objectURL = 'data:'+photoInfo[0].mimeType+';base64,' + photoInfo[0].photoContent;       
        console.log("objectURL="+JSON.stringify(objectURL));
        this.urls[0] = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        console.log("this.image="+JSON.stringify(this.image));
      },(error:HttpErrorResponse)=>{
        this.err=true;
        console.log(error);
        if(!error.error.errors==undefined){
          this.errorMessage=error.error.errors[0].defaultMessage;
            console.log (error.error.errors[0].defaultMessage);
        }
      });
    }
  
    viewContactDetails(): void {

        if(this.ld.member.adminInfo.memberShipType=='Non-Paid-Member'){
          this.validMember=false;
          this.message="Become a Paid Member to view Contact Details";
        }
        if(this.ld.member.adminInfo.memberShipType=='Invalid-Paid-Member'){
          this.validMember=false;
          this.message="Become a Paid Member to view Contact Details";
        }
        if(this.ld.member.adminInfo.memberShipType=='Paid-Member'){  
              this.adminService.showContactDetails(this.ld)
              .subscribe( data => {
                //    console.log("data=");
                  //  console.log(data);
                    if(data!=null && data!=undefined ){
                          this.ld=data;
                          this.loginService.loginDetails=data;
                          if(this.ld.member.adminInfo.memberShipType=='Paid-Member'){
                            this.showContactDetails=true;
                            this.validMember=true;
                          }else{
                            this.showContactDetails=false;
                            this.validMember=false;
                          }
                    }else{
                         alert("Could not update details..");
                    }
              });

              this.message="Contact Details Tab and Phone Details Tab have been added,"+
              "Email Id is added in Basic Details Tab and "+
              "Working Place and office No are added in the Office Details Tab. "+
              "Each click on View Contact Details Button is counted and no of contact details viewed is incremented. "+
              "if you click on the View Contact Details button twice for the same Login Id,"+
              "No of contacts viewed will become 2. So Please click only Once for a Single Login Id. "+
              "You can note down the contact details on a piece of paper."

        }
    };
  
  ngOnInit() {
    this.message="";
    if(this.ld.role=="admin")
            this.showContactDetails=true;
/*
            console.log("photoInfo="+JSON.stringify(this.photoInfo));
    
            let objectURL = 'data:'+this.photoInfo[0].mimeType+';base64,' + this.photoInfo[0].photoContent;       
            console.log("objectURL="+JSON.stringify(objectURL));
            this.image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
            console.log("this.image="+JSON.stringify(this.image));
   */                  
   // this.validMember=this.ld.member.adminInfo.memberShipType=='Paid-Member';
   // console.log(this.member);
  }

}
