// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*
  accessControlAllowOrginUrl:"http://localhost:4200",
  adminUrl:"http://localhost:8080/admin",
  dataUrl : "http://localhost:8080/data",
  loginUrl : "http://localhost:8080/login",
  memberUrl : "http://localhost:8080/members",
  photoUrl : "http://localhost:8080/photoUpload",
  pacUrl : "https://localhost:8080/postacomment",
  schemesUrl : "http://localhost:8080/schemes"
*/

  accessControlAllowOrginUrl:"http://www.dwaithamatrimony.com",
  adminUrl:"http://www.dwaithamatrimony.com/admin",
  dataUrl :"http://www.dwaithamatrimony.com/data",
  loginUrl : "http://www.dwaithamatrimony.com/login",
  photoUrl : "http://www.dwaithamatrimony.com/photoUpload",
  memberUrl : "http://www.dwaithamatrimony.com/members",
  pacUrl : "http://www.dwaithamatrimony.com/postacomment",
  schemesUrl : "http://www.dwaithamatrimony.com/schemes"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
