import {Component, OnInit, AfterViewInit } from '@angular/core';
import {ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSort} from '@angular/material';
import {Member} from '../../../models/Member';
import {LoginDetails} from '../../../models/LoginDetails';
import {EditMemberComponent} from '../edit-member/edit-member.component';
import {ViewMemberComponent} from '../view-member/view-member.component';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {AddMemberComponent} from '../add-member/add-member.component';
import {MatTableDataSource} from '@angular/material';
import {MemberService } from '../../../services/member.service';
import {LoginService } from '../../../services/login.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {SelectionModel} from '@angular/cdk/collections';
import {EditMemberService } from '../../../services/edit-member.service';
import {AddMemberService } from '../../../services/add-member.service';
import {ViewMemberService } from '../../../services/view-member.service';

import {ConfirmDialogService } from '../../../services/confirm-dialog.service';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'app-list-members',
  templateUrl: './list-members.component.html',
  styleUrls: ['./list-members.component.css']
})

export class ListMembersComponent implements AfterViewInit {
  displayedColumns = ['select','id','loginId', 'fatherSurname', 'mname','gothram','subsect','gender','actions'];
  dataSource = new MatTableDataSource<Member>();
  exampleDatabase: MemberService | null;
  membersList: Member[]=new Array<Member>();
  getMembersList;
  index: number;
  id: number;
  deleted;
  selection = new SelectionModel<Member>(true, []);

  constructor(private confirmService:ConfirmDialogService,private addMemberService:AddMemberService,private editMemberService:EditMemberService,private viewMemberService:EditMemberService,private spinner:NgxSpinnerService,private loginService:LoginService,private memberService:MemberService,public dialog: MatDialog) { }
  ld:LoginDetails=this.loginService.loginDetails;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  ngOnInit() {
  /*  console.log("from list component");
    console.log(this.ld);
    console.log(this.loginService.loginDetails);*/
    this.spinner.show();
    this.memberService.getMembers(this.ld).subscribe(
      data => {
    //   console.log(data);
        this.dataSource.data = data;
        this.membersList=data;
        this.spinner.hide();
      }
    );
  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  refreshTable() {
    this.dataSource.data = this.membersList;
  }
  addNew(member: Member) {
      const dialogRef = this.dialog.open(AddMemberComponent, {
      data: {member: member }
      });
      dialogRef.afterClosed().subscribe(result => {
          var result:any=this.addMemberService.result;
          if(result=="success"){
            var member:Member=this.memberService.getDialogData();
            var index=this.membersList.length;
            this.membersList[index]=member;
            this.refreshTable();
          }  
      });
  }
  
  editItem(i: number,id :number) {
      this.id = id;
    //  console.log("id="+id);
     // console.log("i="+i);
      var index=0,j=0;
      var member=new Member();
      this.membersList.forEach(function(usr) {
        if(usr.id===id){
       //   console.log("found the id");
          member=usr;
          index=j;
        } 
        j++;
      });
     // console.log("member="+member);
      const dialogRef = this.dialog.open(EditMemberComponent, {
        data: member
      });
      dialogRef.afterClosed().subscribe(result => {
        var result:any=this.editMemberService.result;
        if(result=="success"){
        this.membersList[index]=this.memberService.getDialogData();
        this.refreshTable();
        } 
      });
  }

  viewItem(i: number,id :number) {
    this.id = id;
   /* console.log("id="+id);
    console.log("i="+i);*/
    var index=0,j=0;
    var member=new Member();
    this.membersList.forEach(function(usr) {
      if(usr.id===id){
      //  console.log("found the id");
        member=usr;
        index=j;
      } 
      j++;
    });
   /* console.log("member="+member);*/
    const dialogRef = this.dialog.open(ViewMemberComponent, {
      data: member
    });
  /*  dialogRef.afterClosed().subscribe(result => {
      var result:any=this.viewMemberService.result;
      if(result=="ok"){
      this.membersList[index]=this.memberService.getDialogData();
      this.refreshTable();
      } 
    });*/
}
  
  deleteItem(i: number,id :number) {
    this.index = i;
    this.id = id;
    var j=0,index=0;
    var member=new Member();
    
    this.membersList.forEach(function(usr) {
      if(usr.id===id){
        member=usr;
        index=j;
      }
      j++; 
    });
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: member
    });
    dialogRef.afterClosed().subscribe(result => {
    //  console.log("result="+result);
      var result:any= this.confirmService.result; 
      console.log("result="+result);
      if(result=="yes"){
         this.deleted=this.deleteMember(member);
          console.log("this.deleted="+this.deleted);
          if(this.deleted=="success"){
            console.log("inside if deleted ==success");
            this.deleted="";
            this.membersList.splice(index, 1);
            this.refreshTable();
          }else{
            this.deleted="";
          }  
      } 
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
 isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSource.data.length;
  return numSelected === numRows;
 }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }
  deleteMembers(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: null
    });
    dialogRef.afterClosed().subscribe(result => {
      
      var result:any= this.confirmService.result; 
      console.log("result="+result);
      if(result=="yes"){
          this.deleted=this.deleteCheckedMembers();
          console.log("this.deleted="+this.deleted);
            
      } 
    });
  }
  deleteMembersFromList(){
    var indexes=[];
    var i=0;
    this.checkedList.forEach(row=>{
      var index=this.membersList.indexOf(row);
      indexes[i]=index;
      i++;
    });
    indexes.forEach(index=>{
      this.membersList.splice(index,1);
    });
  }
  
  checkedList:Member[]=[];
  deleteCheckedMembers():string{
    var checkedList:Member[]=[];
            this.dataSource.data.forEach(row => {
                  if(this.selection.isSelected(row)){
                    checkedList.push(row);
                  }
            });
            console.log("checked list");
            this.checkedList=checkedList;
            if(checkedList.length!=0){     
                        checkedList.forEach(row=>{
                          console.log("selected row id and login Id="+JSON.stringify(row.id)+","+JSON.stringify(row.loginInfo.loginId));
                        });
                        let json = {
                          "membersList":checkedList
                        };
                      //  console.log("json="+JSON.stringify(json));
                        if(this.getMembersList!=undefined)
                            this.getMembersList.unsubscribe();

                        this.getMembersList=this.memberService.deleteMembers(json)
                        .subscribe(
                          res => {
                              this.spinner.hide();
                              console.log("res from deleteCheckedMembers="+res);
                              this.deleted="success";
                              if(res=="success"){
                                this.deleted="";
                                this.deleteMembersFromList();
                                this.refreshTable();
                              }else{
                                this.deleted="";
                              }
                              alert("successfully deleted the records....");
                              
                              // this.getReportData();
                          },(error:HttpErrorResponse)=>{
                              console.log(error);
                              console.log (error.error.errors[0].defaultMessage);
                              this.spinner.hide();
                              this.deleted="failure";
                              alert("error in deleting records....");
                              
                          })
            }
            return this.deleted;
  }

  deleteMember(member): string {
    console.log("member.id="+member.id);
    this.memberService.deleteMember(member)
        .subscribe( 
          data => {
                  console.log(data);
                  if(data!=null && data!=undefined){
                    alert("User deleted successfully.");
                    this.deleted="success";
                  }else{
                    alert("Could not delete the member.");
                    this.deleted="failure";
                  }  
          //this.dialogRef.close();
        },(error:HttpErrorResponse)=>{
            console.log("error=>"+error);
            if(!error.error.errors==undefined){
                console.log (error.error.errors[0].defaultMessage);
            }
        }
      );
      return this.deleted;
  }

}
