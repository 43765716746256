import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent } from './navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule, MatGridListModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, 
  MatCardModule,MatInputModule,MatSelectModule,MatTableModule, MatDialogModule,
   MatPaginatorModule,MatSortModule,MatTabsModule, MatRadioModule, MatDatepickerModule, MatNativeDateModule,DateAdapter, MAT_DATE_FORMATS, MatProgressBar, MatProgressBarModule, MatCheckboxModule} from '@angular/material';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { MainPageComponent } from './main-page/main-page.component';
import { AddMemberComponent } from './main-page/users/add-member/add-member.component';
import { EditMemberComponent } from './main-page/users/edit-member/edit-member.component';
import { ListMembersComponent } from './main-page/users/list-members/list-members.component';
import { routing } from './app.routing';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MainMenuBarComponent } from './main-page/main-menu-bar/main-menu-bar.component';
import { ChangePasswordComponent } from './main-page/users/change-password/change-password.component';
import { LogOutComponent } from './main-page/log-out/log-out.component';
import { MemberService} from './services/member.service';
import { DataService} from './services/data.service';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './main-page/users/confirm-dialog/confirm-dialog.component';
import { DateFormat } from './models/DateFormat';
import {HideTabDirective} from './directives/hide-tab.directive';
/*import { BasicDetailsComponent } from './register/basic-details/basic-details.component';
import { LoginDetailsComponent } from './register/login-details/login-details.component';
import { PhoneDetailsComponent } from './register/phone-details/phone-details.component';
import { AddressDetailsComponent } from './register/address-details/address-details.component';
import { FamilyDetailsComponent } from './register/family-details/family-details.component';
import { IndepthDetailsComponent } from './register/indepth-details/indepth-details.component';
import { OfficeDetailsComponent } from './register/office-details/office-details.component';*/
import { RegisterSuccessComponent } from './register-success/register-success.component';
import { ViewMemberComponent } from './main-page/users/view-member/view-member.component';
import { ViewLoggedMemberComponent } from './main-page/users/view-logged-member/view-logged-member.component';
import { EditLoggedMemberComponent } from './main-page/users/edit-logged-member/edit-logged-member.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PaymentDetailsComponent } from './payment-details/payment-details.component';
import { SchemesComponent } from './schemes/schemes.component';
import { DonationsComponent } from './donations/donations.component';
import { PostACommentComponent } from './post-a-comment/post-a-comment.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { AdminDetailsComponent } from './main-page/admin-details/admin-details.component';
import { ProcedureComponent } from './procedure/procedure.component';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { PhotoDetailsComponent } from './register/photo-details/photo-details.component';
import { NgxSpinnerModule} from 'ngx-spinner';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    MainPageComponent,
    AddMemberComponent,
    EditMemberComponent,
    ViewMemberComponent,
    ListMembersComponent,
    MainMenuBarComponent,
    ChangePasswordComponent,
    LogOutComponent,
    ConfirmDialogComponent,
   /* BasicDetailsComponent,
    LoginDetailsComponent,
    PhoneDetailsComponent,
    AddressDetailsComponent,
    FamilyDetailsComponent,
    IndepthDetailsComponent,
    OfficeDetailsComponent,*/
    RegisterSuccessComponent,
    ViewLoggedMemberComponent,
    EditLoggedMemberComponent,
    AboutUsComponent,
    PaymentDetailsComponent,
    SchemesComponent,
    DonationsComponent,
    PostACommentComponent,
    ContactDetailsComponent,
    AdminDetailsComponent,
    ProcedureComponent,
    PhotoDetailsComponent,
    HideTabDirective,
    
   ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,

    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCheckboxModule,
        
    MatCardModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatSortModule,
    MatSelectModule,
    MatTabsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFileUploadModule,
    MatProgressBarModule,
   
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    routing,
    NgxSpinnerModule
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ViewMemberComponent
   ],
  providers: [
    DataService,MemberService,
    
      { provide: DateAdapter, useClass:DateFormat   },
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private dateAdapter:DateAdapter<Date>) {
    dateAdapter.setLocale('en-in'); // DD/MM/YYYY
    }
 }
 /*MatFileUploadModule,*/