export class AddressInfo{
	public  houseNo:string;
	public landmark:string;
	public street:string;
/*	int countryId;
	int stateId;
	int districtId;
	int mandalId;
	int villageId;*/
	//int pincode;
	
	 public address:string;
     public district:string;
     public state:string;
     public country:string;
	 public pincode:string;
	 public contactPersonName:string;
	 public  contactPersonRelation: string;
}